<template>
  <h1 style="direction: RTL;">
    בכל מקרה של בעיה או בקשה נא לפנות ל:
    <a href="mailto:simpoboxinfo@gmail.com">simpoboxinfo@gmail.com</a>
  </h1>
</template>

<script>
export default {
  name: 'Support',
  components: {

  },
  data() {
    return {

    }
  }
}
</script>